import React, { useState } from 'react';
import ModalVideo from 'react-modal-video'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import img1 from '../../images/home_headers/leadspace-image1.jpg';
import img2 from '../../images/home_headers/leadspace-image2.jpg';
import img3 from '../../images/home_headers/leadspace-image3.jpg';
import playBtn from '../../images/play_btn.svg';
import './homeLeadspace.scss';

const HomeLeadspace = () => {
  let [modalOpen, setModalOpen] = useState(false);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    centerPadding: 0,
    className: 'lead-carousel',
    lazyLoad: 'progressive',
    autoplay: true,
    autoplaySpeed: 4000
  };

  const toggleModal = () => {
    modalOpen ? setModalOpen(false) : setModalOpen(true);
    const iframe = document.getElementsByTagName('iframe')[0];
    iframe.contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
  };

  const playClick = (e) => {
    e.preventDefault();
    setModalOpen(true);
  };

  return (
    <div id="home-leadspace">
      <Slider {...sliderSettings}>
        <div>
          <img src={img1} alt="home 10" />
        </div>
        <div>
          <img src={img2} alt="home 11" />
        </div>
        <div>
          <img src={img3} alt="home 8" />
        </div>
      </Slider>
      <div id="leadspace-content">
        <div id="leadspace-title">
          <button id="leadspace-play" onClick={playClick}>
            <img src={playBtn} alt="Play Video" />
          </button>
          <h1>
            Leadership <br />
            Never Stops.
          </h1>
          <p>
            <span className="bold">Resilience in the face of disruption starts with leadership.</span> At CCL, we believe in the power of leaders to ignite transformational change, to navigate the crisis of the moment while preparing for the changed world that comes next. <a href="#" onClick={playClick}>See why now</a>, more than ever, we believe that <b>leadership never stops</b>.
          </p>
        </div>
      </div>
      <div className={modalOpen ? null : "hidden"}>
        <ModalVideo channel='youtube' isOpen videoId='U5gf51-wG18' onClose={toggleModal} youtube={{ autoplay: false, enablejsapi: 1, rel: 0 }} />
      </div>
    </div>
  );
};

export default HomeLeadspace;
